<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'base-header-closable',
  components: {
    BaseButton,
  },
  inheritAttrs: false,
  methods: {
    onClose() {
      modal.hide()
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div class="relative">
    <slot />
    <div class="absolute right-0 top-0 z-10">
      <base-button icon="plain/close" class="p-5" @click="onClose" />
    </div>
  </div>
</template>
