<script setup lang="ts">
import { computed } from 'vue'
import HeaderClosable from './closable/header-closable.vue'
import HeaderDefault from './default/header-default.vue'
import HeaderMobileBack from './mobile-back/header-mobile-back.vue'
import HeaderMobileImage from './mobile-image/header-mobile-image.vue'
import HeaderMobilePlain from './mobile-plain/header-mobile-plain.vue'

const props = withDefaults(
  defineProps<{
    type?:
      | 'default'
      | 'mobile-plain'
      | 'mobile-image'
      | 'mobile-back'
      | 'closable'
  }>(),
  {
    type: 'default',
  }
)

const target = {
  default: HeaderDefault,
  'mobile-plain': HeaderMobilePlain,
  'mobile-image': HeaderMobileImage,
  'mobile-back': HeaderMobileBack,
  closable: HeaderClosable,
}

const component = computed(() => target[props.type])
</script>

<template>
  <component :is="component" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) in $slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </component>
</template>
